export class Propiedades {
    propiedad_ID: number;
    propiedad_desc: string;
    propiedad_importe ?: number;
    propiedad_periodos ?: number;
    propiedad_imPeriodo ?: number;
    propiedad_impDeprec ?: number;
    propiedad_impPorDeprec ?: number;
    propiedad_perDeprec ?: number;
    propiedad_depreciado ?: boolean;
    propiedad_centroCostoID : number;
    propiedad_tipo ?: number;
    propiedad_extens ?: number;
    propiedad_unidad ?: number;
    propiedad_longitud :string;
    propiedad_latitud :string;
    propiedad_imagen :string;
    propiedad_status ?: boolean;
    propiedad_obs : string;

    constructor()
    {
      this.propiedad_impDeprec = 0;
      this.propiedad_perDeprec = 0;
      this.propiedad_depreciado = false;
    }
}