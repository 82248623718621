export class CompraProductoDetalle {
  COMD_ID: number;
  COMD_NoRenglon: number;
  COMD_InfoProducto: string;
  COMD_Observaciones: string;
  COMD_Cantidad: number;
  COMD_CostoUnitario: number;
  COMD_Subtotal: number;
  COMD_Descuentos: number;
  COMD_Impuestos: number;
  COMD_Total: number;
  COMD_Status: string;
  ID_Compra: number;
  ID_Producto: number;
  COMD_CostoUnitarioDescuento?: number;
  constructor() {
    this.COMD_Cantidad = 0;
    this.COMD_NoRenglon = 0;
    this.COMD_InfoProducto = '';
    this.COMD_Status = 'A';
    this.COMD_Observaciones = 'NA';
  }
}
