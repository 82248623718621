import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { Marcas } from '../../models/Marcas';

@Injectable({
  providedIn: 'root'
})
export class MarcasService {
  private _url = `${AppSettings.API_ENDPOINT}/Marcas`;
  private _getLista = `${this._url}/Lista`;
  private _getGetMarca = `${this._url}/GetMarca`;
  private _guardar = `${this._url}/Guardar`;
  private _eliminar = `${this._url}/Eliminar`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
      this._userService.loadStorage();
  }

  getLista(nombre: String, estatus: number): Observable<any[]> {
    const params = new HttpParams().set('marcas', nombre.toString())
                                    .set('estatus', estatus.toString());
    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header })
        .pipe(
            tap(data => data),
            catchError(this.handleError)
        );
}

getMarcas(id: number): Observable<Marcas> {
  return this._http.get<Marcas>(`${this._getGetMarca}/${id}`, { headers: this._userService.header })
      .pipe(
          tap(data => data),
          catchError(this.handleError)
      );
}

guardar(_marca: any): Observable<Marcas> {
  return this._http.post<Marcas>(`${this._guardar}`, _marca, { headers: this._userService.header })
      .pipe(
          tap(data => data),
          catchError(this.handleError)
      );
}

eliminar(id: number) {
  return this._http.delete(`${this._eliminar}/${id}`, { headers: this._userService.header }).pipe(
      tap(data => data),
      catchError(this.handleError)
  );
}

// Handdle Error methor for observale
private handleError(err: HttpErrorResponse) {
  return throwError(err.error);
}
}
