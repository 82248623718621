import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { UsersService } from "../../services/users/users.service";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { AplicacionGastos } from "../../models/AplicacionGastos";
import { AplicacionGastosService } from "../../services/aplicacionGastos/aplicacion-gastos.service";
import { SiembraLotes } from "src/app/models/SiembraLotes";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AplicacionDepreciacion } from '../../models/AplicacionDepreciacion';

@Component({
  selector: "app-ap-gastos",
  templateUrl: "./ap-gastos.component.html",
  styleUrls: ["./ap-gastos.component.css"],
})
export class ApGastosComponent implements OnInit {
  // configuracion del modal
  @ViewChild("editModal") editModal: ModalDirective;
  @ViewChild('templateDepreciacion') templateDepreciacion;
  modalRef: BsModalRef;
  modalDepr: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  configLg = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configExtraLg = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  // variables para las fechas de filtros
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  // variables para las fechas de alta
  hoveredDate2: NgbDate | null = null;
  fromDate2: NgbDate | null;
  toDate2: NgbDate | null;

  pageActual = 1;
  pActual = 1;
  paActual = 1;

  // configuracion de la notificacion
  public toastconfig: any = {
    timeOut: 0,
    extendedTimeOut: 0,
    preventDuplicates: true,
    maxOpened: 1,
    autoDismiss: false,
  };
  selectedItemsList = [];
  selectall = false;

  // variables para obtener los filtros
  statusFilter = 0;
  gastoFilter = null;
  fechaIniFilter = " ";
  fechaFinFilter = " ";
  centroCostoFilter = null;
  tipoGastoFilter = null;
  modoCalc = 0;

  desgloceTrab = false;
  etiquetaDesgloce = "Asignar Trabajador";

  apGastos: any[] = []; // guarda la lista obtenida segun el filtrado
  apGasto: AplicacionGastos = new AplicacionGastos(); // para guardar lo capturado en la BD
  apTotal: number;
  apDesglosar = false;
  //detallesGatos: SiembraLotes = new SiembraLotes();
  // deshabilitar el activo/inactivo cuando se da de alta uno nuevo
  nuevoItem = true;

  // obtiene los tipos de estatus
  getEstatus = [
    { ID: 1, Nombre: "Todos", Tipo: 0 },
    { ID: 2, Nombre: "Activos", Tipo: 1 },
    { ID: 3, Nombre: "Inactivos", Tipo: 2 },
  ];

  // obtiene manual /auto
  getModoCalc = [
    { ID: 1, Nombre: "Manual", Tipo: 0 },
    { ID: 2, Nombre: "Automatico", Tipo: 1 },
  ];

  getTipoActivo = [
    { ID: 1, Nombre: "Maquinaria o  Eq. de Transporte", Tipo: true },
    { ID: 2, Nombre: "Propiedad", Tipo: false }
  ];

  // obtener fecha actual del sistema
  tiempoTranscurrido = Date.now();
  hoy = new Date(this.tiempoTranscurrido);

  // guardan la lista obtenida segun lo que contenga cada uno
  getGasto: any[] = []; // alta de gastos
  getGastoFiltro: any[] = []; // alta de gastos
  getCentroCosto: any[] = []; // guarda la lista obtenida segun el filtrado
  getTipoGasto: any[] = []; // guarda la lista obtenida segun el filtrado
  getFiltroTipoGasto: any[] = []; // guarda la lista obtenida segun el filtrado
  getApSiembra: SiembraLotes[] = []; // guarda la lista obtenida segun el filtrado
  getLotes: any[] = []; // guarda la lista obtenida segun el filtrado
  getSiembra: any[] = []; // guarda la lista obtenida segun el filtrado
  seleccionado: any[] = []; //Items a guardar de la tabla

  // manejo del select en la ap siembra
  select = false;
  checkTrab = false;
  seleccionados = 0; //Conteo de seleccionados de la tabla
  activar = [{ ID: 0 }];
  trabajadores = [
    { ID: 1, Nombre: "Felipe", Tipo: 0 },
    { ID: 2, Nombre: "Automatico", Tipo: 1 },
  ];

  ///// VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
  deshabilitar = false;

  apDepreciacion: AplicacionDepreciacion = new AplicacionDepreciacion(); 
  getMaqEquipo: any[] = []; 
  getPrpiedades: any[] = []; 
  totPeriodos = 0;

  // tslint:disable-next-line: variable-name
  constructor(
    private router: Router,
    private _userService: UsersService,
    private _apGastosService: AplicacionGastosService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this._userService.loadStorage();
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }

  ngOnInit() {
    this.onBuscar();
    this.GetLotes();
    this.GetSiembras();
    this.GetGetCentroCosto();
    this.cleanDate();
    this.desgloceTrab = false;
    this.fetchSelectedItems();
  }
  
  changeSelection(event) {
    this.fetchSelectedItems();
    event.target.checked = true;
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.getApSiembra.filter((value, index) => {
      return value.registroSeleccionado
    });
  }

  selectAllTrab() {
    /*
    for (let item of this.items) {
      item.select = this.checkTrab;
    }
    */
    //this.getApSiembra.forEach(nombre => this.checkboxes[nombre] = false);
  }
  all(form) {
    console.log("ALL DEL FORM");
    console.log("LEN APSIEMBRA " + this.getApSiembra.length);
    for (let i = 0; this.getApSiembra.length; i++) {
      form.detalles[i].checked = true;
    }
  }
  diClic(event) {
    var isChecked = event.currentTarget.checked;
    console.log("DI CLIC");
    console.log(isChecked);
  }
  //Agregar seleccionados a uns lista temporal para el costeo
  rowSelected(item: any) {
    var dup = false;
    var i = 0;
    for (let ap of this.getApSiembra) {
      if (item.siembra_id == ap.siembra_id && item.lote_ID == ap.lote_ID) {
        for (let se of this.seleccionado) {
          i++;
          if (item.siembra_id == se.siembra_id) {
            console.log("ELIMINADO ARRAY: " + item.siembra_descripcion);
            this.seleccionado.pop();
            dup = true;
          } else {
            dup = false;
          }
        }
        if (!dup) {
          item.add("index", i);
          this.activar.push(item.siembra_id);
          this.seleccionado.push(item);
          console.log("SELECCIONADO ARRAY: " + item.siembra_descripcion);
          console.log("INDEX ARRAY: " + item.index);
        }
      }
    }

    console.log("ARRAY LEN: " + this.seleccionado.length);
    this.seleccionados = this.seleccionado.length;
    for (let it of this.seleccionado) {
      console.log("ARRAY ITEM: " + it.siembra_descripcion);
    }
  }

  selectCheck() {
    // tslint:disable-next-line: prefer-const
    let elementosDelForm = document.getElementsByTagName("input"); // Elementos input
    const checkboxElements = new Array();

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elementosDelForm.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (elementosDelForm[i].type == "checkbox") {
        checkboxElements.push(elementosDelForm[i]);
        console.log("PUSH");
      }
    }

    /*
    for (var i=0; i<sel.length; i++) {

      if (sel[i].checked == true) {
        console.log("SELECCIONADO " + i);
      }

    }
    */
    /*
    for (let i; this.getApSiembra.length; i++)
    {
      if(("#check"+i) == "checked")
      {
        console.log("SELECCIONADO " + i);
      }
    }
    */
  }

  cleanDate() {
    this.hoveredDate = null;
    this.fromDate = null;
    this.toDate = null;

    this.hoveredDate2 = null;
    this.fromDate2 = null;
    this.toDate2 = null;
  }

  onBuscar() {
    this.checkCombos();
    this._apGastosService
      .getLista(
        this.fechaIniFilter,
        this.fechaFinFilter,
        this.statusFilter,
        this.centroCostoFilter,
        this.tipoGastoFilter,
        this.gastoFilter
      )
      .subscribe(
        (data: any) => {
          this.apGastos = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  checkCombos() {
    if (this.statusFilter == null) {
      this.statusFilter = 0;
    }
    // if (this.tipoGastoFilter == null) {
    //   this.tipoGastoFilter = " ";
    // }
    // if (this.gastoFilter == null) {
    //   this.gastoFilter = " ";
    // }
    // if (this.fechaFinFilter == null) {
    //   this.fechaFinFilter = " ";
    // }
    // if (this.fechaIniFilter == null) {
    //   this.fechaIniFilter = " ";
    // }
    // if (this.centroCostoFilter == null) {
    //   this.centroCostoFilter = " ";
    // }
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      // this.apGasto.apliGasto_fechaCapt = new Date(
      //   this.hoy.toLocaleDateString()
      // );
      // this.apGasto.apliGasto_fechaApli = new Date(
      //   this.toDate.year,
      //   this.toDate.month - 1,
      //   this.toDate.day
      // );

      const model = {
        model: this.apGasto,
        siembras: this.getApSiembra,
      };

      this._apGastosService.guardar(model).subscribe(
        (success) => {
          this.toastr.success("Gasto guardado con éxito.", "Guardado!");
          this.onBuscar();
          FormData.resetForm();
          this.cleanDate();
          this.modalRef.hide();
        },
        (error) => {
          this.toastr.error(error.message, "Error!");
        }
      );
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar la aplicación de gato, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this._apGastosService.eliminar(id).subscribe(
          (success) => {
            this.onBuscar();
            Swal.fire({
              title: "Eliminado!",
              text: "Aplicación de gasto a sido eliminada con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
          },
          (error) => {
            this.toastr.error(error.message, "Error!");
          }
        );
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.GetgetApSiembra();
    this.apGasto = new AplicacionGastos();

    if (id <= 0) {
      this.deshabilitar = false;
      this.nuevoItem = true;
      this.modalRef = this.modalService.show(template, this.configExtraLg);
      this.apGasto.apliGasto_status = true;
      this.cleanDate();
    } else {
      this._apGastosService.getApGasto(id).subscribe(
        (data) => {
          this.apGasto = data;

          const fDate = new Date(this.apGasto.apliGasto_fechaApli);

          this.fromDate = new NgbDate(
            fDate.getFullYear(),
            fDate.getMonth() + 1,
            fDate.getDate()
          );
          // this.toDate = new NgbDate (tDate.getFullYear(),tDate.getMonth()+1,tDate.getDate());

          this.modalRef = this.modalService.show(template, this.configExtraLg);
        },
        (error) => this.toastr.error(error.message, "Error!")
      );

      //// HABILITA O NO el campo de activo/inactivo
      // porque ya existe uno en el caso de cuando es un nuevo item o un editar
      if (this.deshabilitar) {
        this.nuevoItem = true;
      } else {
        this.deshabilitar = false;
        this.nuevoItem = false;
      }
    }
  }

  onTipoGastoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.getGasto = [];

    this._apGastosService.getGastos(selectedValue.tipoGasto_id).subscribe(
      (data) => {
        this.getGasto = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );

    if (selectedValue.tipoGasto_id == 16) {
      this.onShowDepreciacion();
    }
  }
  
  onCentroChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.getTipoGasto =[];
    this.apGasto.apliGasto_tipoGasto = null;
    this.getGasto = [];
    this.apGasto.apliGasto_gastoID = null;

    this._apGastosService.getTipoGasto(selectedValue.centroCosto_id).subscribe(
      (data) => {
        this.getTipoGasto = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );

  }

  onFiltroTipoGastoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.getGastoFiltro = [];
    this.gastoFilter = null;
    this._apGastosService.getGastos(selectedValue.tipoGasto_id).subscribe(
      (data) => {
        this.getGastoFiltro = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );
  }

  onFiltroCentroChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.getFiltroTipoGasto = [];
    this.tipoGastoFilter = null;
    this.getGastoFiltro = [];
    this.gastoFilter = null;
    this._apGastosService.getTipoGasto(selectedValue.centroCosto_id).subscribe(
      (data) => {
        this.getFiltroTipoGasto = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );

  }

  
  
  // getGastos() {
  //   this._apGastosService.getGastos().subscribe(
  //     (data) => {
  //       this.getGasto = data;
  //     },
  //     (error) => this.toastr.error(error.message, "Error!")
  //   );
  // }

  GetGetCentroCosto() {
    this._apGastosService.getCentroCosto().subscribe(
      (data) => {
        this.getCentroCosto = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );
  }

  // GetgetTipoGasto() {
  //   this._apGastosService.getTipoGasto().subscribe(
  //     (data) => {
  //       this.getTipoGasto = data;
  //     },
  //     (error) => this.toastr.error(error.message, "Error!")
  //   );
  // }

  GetgetApSiembra() {
    this._apGastosService.getListaSiembraLotes().subscribe(
      (data) => {
        
        this.getApSiembra = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );

  }

  GetSiembras() {
    this._apGastosService.getSiembras().subscribe(
      (data) => {
        this.getLotes = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );
  }

  GetLotes() {
    this._apGastosService.getLotes().subscribe(
      (data) => {
        this.getSiembra = data;
      },
      (error) => this.toastr.error(error.message, "Error!")
    );
  }

  mostrarToF(valor: boolean, op: number): string {
    let res = "";
    // tslint:disable-next-line: triple-equals
    if (op == 1) {
      return valor ? (res = "Si") : (res = "No");
    }
    // tslint:disable-next-line: triple-equals
    if (op == 2) {
      return valor ? (res = "Activo") : (res = "Inactivo");
    }
  }

  cerrar() {
    this.router.navigate(["/dashboard"]);
  }

  //////  METODOS PARA LA SELECCION DEL RANGO DE FECHAS ////////
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  //////  METODOS PARA LA SELECCION DEL RANGO DE FECHAS  2////////
  onDateSelection2(date: NgbDate) {
    if (!this.fromDate2 && !this.toDate2) {
      this.fromDate2 = date;
    } else if (
      this.fromDate2 &&
      !this.toDate2 &&
      date &&
      date.after(this.fromDate2)
    ) {
      this.toDate2 = date;
    } else {
      this.toDate2 = null;
      this.fromDate2 = date;
    }
  }

  isHovered2(date: NgbDate) {
    return (
      this.fromDate2 &&
      !this.toDate2 &&
      this.hoveredDate2 &&
      date.after(this.fromDate2) &&
      date.before(this.hoveredDate2)
    );
  }

  isInside2(date: NgbDate) {
    return (
      this.toDate2 && date.after(this.fromDate2) && date.before(this.toDate2)
    );
  }

  isRange2(date: NgbDate) {
    return (
      date.equals(this.fromDate2) ||
      (this.toDate2 && date.equals(this.toDate2)) ||
      this.isInside2(date) ||
      this.isHovered2(date)
    );
  }

  validateInput2(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  onFechaAplChanged(selectedDate: NgbDateStruct) {
    this.apGasto.apliGasto_fechaApli = this.getDateFromDateStruct(selectedDate);
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }


  onTipoActivoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    if (selectedValue.Tipo === true) {
      this._apGastosService.getMaquinaria().subscribe(
        (data) => {
          this.getMaqEquipo = data;
        },
        (error) => this.toastr.error(error.message, "Error!")
      );
    } else {
      this._apGastosService.getPropiedades().subscribe(
        (data) => {
          this.getPrpiedades = data;
        },
        (error) => this.toastr.error(error.message, "Error!")
      );

    }
  }

  onActivoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    if (this.apDepreciacion.apliDep_tipoActivo === true) {
     this.apDepreciacion.apliDep_importe = selectedValue.maqEquipo_impDeprec;
     this.apDepreciacion.apliDep_periodo = selectedValue.maqEquipo_perDepreciados + 1;
     this.totPeriodos = selectedValue.maqEquipo_perADeprec;
    } else {
      this.apDepreciacion.apliDep_importe = selectedValue.propiedad_impDeprec;
      this.apDepreciacion.apliDep_periodo = selectedValue.propiedad_perDeprec + 1;
      this.totPeriodos = selectedValue.propiedad_periodos;
    }
  }

  onSubmitDepreciacion(FormData) {
    if (FormData.valid) {
      this.toastr.success("Depreciacion guardado con éxito.", "Guardado!");
      this.apGasto.apliGasto_importe = this.apDepreciacion.apliDep_importe;
      this.modalDepr.hide();
    }
  }

  onShowDepreciacion() {
    this.apDepreciacion = new AplicacionDepreciacion(); 
    this.getMaqEquipo = []; 
    this.getPrpiedades = []; 
    this.totPeriodos = 0;

    this.modalDepr = this.modalService.show(this.templateDepreciacion, this.config);
  }

  onCerrarDepreciacion() {
    this.modalDepr.hide();
    this.apDepreciacion = new AplicacionDepreciacion(); 
    this.getMaqEquipo = []; 
    this.getPrpiedades = []; 
    this.totPeriodos = 0;
  }

  onSelectAll() {
    if (this.selectall) {
     this.getApSiembra.forEach(box => {
       box.registroSeleccionado = true;
     });
    } else {
     this.getApSiembra.forEach(box => {
       box.registroSeleccionado = false;
     });
    }
  }

  OnUpdateTotal() {
    this.apTotal = 0;
    this.getApSiembra.forEach(box => {
      let valueToSum = 0;
      if (box.Importe == null || box.Importe == undefined) {
        valueToSum = 0;
      } else {
        valueToSum = box.Importe;
      }
      this.apTotal += Number(valueToSum);
    });
  }

  onRecalcular() {
    if (this.modoCalc ==  0) {
      this.apTotal = 0;
      this.getApSiembra.forEach(box => {
        let valueToSum = 0;
        if (box.Importe == null || box.Importe == undefined) {
          valueToSum = 0;
        } else {
          valueToSum = box.Importe;
        }
        this.apTotal += Number(valueToSum);
      });      
    }else{
      this.apTotal = 0;
      if (this.apGasto.apliGasto_importe == null || this.apGasto.apliGasto_importe == undefined) {
        this.toastr.error("Favor de capturar el importe total del gasto", "Error!");
        return;
      }

      let sumaSuperficies = 0;
      this.getApSiembra.forEach(box => {
        sumaSuperficies += Number(box.lote_superficie);
      }); 

      this.getApSiembra.forEach(box => {
        box.Importe = (box.lote_superficie / sumaSuperficies) * this.apGasto.apliGasto_importe;
        box.registroSeleccionado = true;
        this.apTotal += Number(box.Importe);
      }); 

      this.selectall = true;
    }
  }
}
