import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Gastos } from '../../models/Gastos';
import { GastosService } from '../../services/gastos/gastos.service';



@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.css']
})
export class GastosComponent implements OnInit {
  //configuracion del modal
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  //variables para obtener los filtros
  descripcionFilter: string = '';
  estatusFilter: number = 0;
  centroCostoFilter = null;
  tipoGastoFilter = null;

  pageActual: number = 1; //contador para la paginacion de la tabla de agroquimicos
  pActual: number = 1; //contador para la paginacion de la tabla detalles agroq. (ingredientes)

    //configuracion de la notificacion
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  
  // obtiene los tipos de estatus
  getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
  {ID: 3, Nombre: 'Inactivos', Tipo: 2}];

  /*
  // obtiene los tipos de gastos
  getTipos = [{ID: 1, Nombre: 'Maquinaria', Tipo: 1}, 
              {ID: 2, Nombre: '  Equipo de Transporte', Tipo: 2},
              {ID: 3, Nombre: 'Maquinaria-Equipo', Tipo: 3},
              {ID: 4, Nombre: 'Oficina', Tipo: 4},
              {ID: 5, Nombre: 'Campo', Tipo: 5},
              {ID: 6, Nombre: 'Oficina-Campo', Tipo: 6}];
*/
  ///PARA MANEJO DE LA TABLA GASTOS
  gasto:  Gastos = new  Gastos(); //para guardar lo capturado en la BD
  gastos: any[] = []; //guarda la lista obtenida segun el filtrado

  getCentroCosto: any[] = []; //guarda la lista obtenida segun el filtrado
  getTipoGasto: any[] = []; //guarda la lista obtenida segun el filtrado

//deshabilitar el activo/inactivo cuando se da de alta uno nuevo
nuevoItem = true;
      /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
deshabilitar: boolean = false;  

constructor(private router: Router, private _userService: UsersService, 
  private _gastosService: GastosService,
  private modalService: BsModalService, private toastr: ToastrService) {
  this._userService.loadStorage();
}


ngOnInit() {
  this.onBuscar();
  this.GetgetCentroCosto();
  this.GetgetTipoGasto();
}
checkCombos()
{
  if(this.estatusFilter == null)
  {
    this.estatusFilter = 0;
  }
  if(this.centroCostoFilter == null)
  {
    this.centroCostoFilter = null;
  }
  if(this.tipoGastoFilter == null)
  {
    this.tipoGastoFilter = null;
  }
}
onBuscar() {
 
  this.checkCombos();
  this._gastosService.getLista(this.descripcionFilter,this.centroCostoFilter,this.tipoGastoFilter,this.estatusFilter).subscribe(
    (data: any) => {
      this.gastos = data;
    },
    (error) => {
      Swal.fire({
        title: 'Error!',
        text: String(error.message),
        type: 'error',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      });
    }
  );
}

onSubmit(FormData) {
  if (FormData.valid) {
    this._gastosService.guardar(this.gasto)
  .subscribe(
    success => {
      this.toastr.success('Gasto guardado con exito.', 'Guardado!');
      this.onBuscar();
      FormData.resetForm();
      this.modalRef.hide();
    },
    error => {
      this.toastr.error(error.message, 'Error!');
    });
  }
}

onDelete(id: number) {
  Swal.fire({
    title: 'Esta seguro?',
    text: 'Esta seguro que quiere eliminar el gasto, no se podra revertir!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Eliminar!',
    focusConfirm: false,
    focusCancel: false,
    allowEnterKey: false
  }).then((result) => {
    if (result.value) {
      this._gastosService.eliminar(id)
      .subscribe(
        success => {
          this.onBuscar();
          Swal.fire({
            title: 'Eliminado!',
            text: 'Gasto a sido eliminada con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  });
}

onShow(id: number, template: TemplateRef<any>) {
  this.gasto = new Gastos();
  if (id <= 0) {
    this.deshabilitar = false;
    this.modalRef = this.modalService.show(template, this.config);
    this.gasto.gastos_status = true;
    this.nuevoItem=true;
    
  } else {
    this._gastosService.getGasto(id)
  .subscribe(
    data => {
      this.gasto = data;
      this.modalRef = this.modalService.show(template, this.config);
    },
    error => this.toastr.error(error.message, 'Error!') );

      ////HABILITA O NO el campo de activo/inactivo 
      //porque ya existe uno en el caso de cuando es un nuevo item o un editar
      if(this.deshabilitar)
      {
        this.nuevoItem = true;
      }
      else
      {
        this.deshabilitar = false;
        this.nuevoItem = false;
      }
  }
}

GetgetCentroCosto() {
  this._gastosService.getCentroCosto()
    .subscribe(
      data => {
        this.getCentroCosto = data;
      },
      error => this.toastr.error(error.message, 'Error!') );
}

GetgetTipoGasto() {
  this._gastosService.getTipoGasto()
    .subscribe(
      data => {
        this.getTipoGasto = data;
      },
      error => this.toastr.error(error.message, 'Error!') );
}

mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        return valor ? res = "Si" : res = "No";
      }
      if(op == 2)
      {
        return valor ? res = "Activo" : res = "Inactivo";
      }
      
    }

    mostrarTipo(op: number): string
    {
      switch(op)
      {
          case 1: return "Maquinaria";
          case 2: return "Equipo y Transporte";
          case 3: return "Maquinaria-Equipo";
          case 4: return "Oficina";
          case 5: return "Campo";
          case 6: return "Oficina-Campo";
      }
    }

    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }

}
