import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { TipoGasto } from '../../models/TipoGasto';
import { TipoGastoService } from '../../services/tipoGasto/tipo-gasto.service';

@Component({
  selector: 'app-tipo-gasto',
  templateUrl: './tipo-gasto.component.html',
  styleUrls: ['./tipo-gasto.component.css']
})
export class TipoGastoComponent implements OnInit {

  //configuracion del modal
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  //variables para obtener los filtros
  descripcionFilter: string = '';
  estatusFilter: number = 0;
  centroCostoFilter: string = '';

  pageActual: number = 1; //contador para la paginacion de la tabla de agroquimicos

    //configuracion de la notificacion
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  
  // obtiene los tipos de estatus
  getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
  {ID: 3, Nombre: 'Inactivos', Tipo: 2}];

  // obtiene la naturaleza
  getSeleccion = [{ID: 1, Nombre: 'General', Tipo: 1}, {ID: 2, Nombre: 'Mano de Obra', Tipo: 2},
                  {ID: 3, Nombre: 'Depreciación', Tipo: 3}, {ID: 4, Nombre: 'Maquinaria o Equipo', Tipo: 4}];

  ///PARA MANEJO DE LA TABLA GASTOS
  tipoGasto: TipoGasto = new  TipoGasto(); //para guardar lo capturado en la BD
  tipoGastos: any[] = []; //guarda la lista obtenida segun el filtrado

  getCentroCosto: any[] = []; //guarda la lista obtenida segun el filtrado

//deshabilitar el activo/inactivo cuando se da de alta uno nuevo
nuevoItem = true;
      /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
deshabilitar: boolean = false;  

constructor(private router: Router, private _userService: UsersService, 
  private _tipoCostoService: TipoGastoService,
  private modalService: BsModalService, private toastr: ToastrService) {
  this._userService.loadStorage();
}

ngOnInit() {
  this.onBuscar();
  this.GetgetCentroCosto();
}
checkCombos()
{
  if(this.centroCostoFilter == null)
  {
    this.centroCostoFilter = '';
  }
  if(this.estatusFilter == null)
  {
    this.estatusFilter = 0;
  }

}
onBuscar() {
 
  this.checkCombos();
  this._tipoCostoService.getLista(this.descripcionFilter, this.estatusFilter,this.centroCostoFilter).subscribe(
    (data: any) => {
      this.tipoGastos = data;
    },
    (error) => {
      Swal.fire({
        title: 'Error!',
        text: String(error.message),
        type: 'error',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      });
    }
  );
}

onSubmit(FormData) {
  if (FormData.valid) {
    this._tipoCostoService.guardar(this.tipoGasto)
  .subscribe(
    success => {
      this.toastr.success('Tipo de Gasto guardado con exito.', 'Guardado!');
      this.onBuscar();
      FormData.resetForm();
      this.modalRef.hide();
    },
    error => {
      this.toastr.error(error.message, 'Error!');
    });
  }
}

onDelete(id: number) {
  Swal.fire({
    title: 'Esta seguro?',
    text: 'Esta seguro que quiere eliminar el tipo de gasto, no se podra revertir!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Eliminar!',
    focusConfirm: false,
    focusCancel: false,
    allowEnterKey: false
  }).then((result) => {
    if (result.value) {
      this._tipoCostoService.eliminar(id)
      .subscribe(
        success => {
          this.onBuscar();
          Swal.fire({
            title: 'Eliminado!',
            text: 'Tipo de Gasto a sido eliminado con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
        },
        error => {
          this.toastr.error("El tipo de gasto esta asignado a otro componente", 'Error!');
        });
    }
  });
}

onShow(id: number, template: TemplateRef<any>) {
  this.tipoGasto = new TipoGasto();
  if (id <= 0) {
    this.deshabilitar = false;
    this.modalRef = this.modalService.show(template, this.config);
    this.tipoGasto.tipoGasto_status= true;
    this.nuevoItem=true;
    
  } else {
    this._tipoCostoService.getTipoGasto(id)
  .subscribe(
    data => {
      this.tipoGasto = data;
      this.modalRef = this.modalService.show(template, this.config);
    },
    error => this.toastr.error(error.message, 'Error!') );

      ////HABILITA O NO el campo de activo/inactivo 
      //porque ya existe uno en el caso de cuando es un nuevo item o un editar
      if(this.deshabilitar)
      {
        this.nuevoItem = true;
      }
      else
      {
        this.deshabilitar = false;
        this.nuevoItem = false;
      }
  }
}

GetgetCentroCosto() {
  this._tipoCostoService.getCentroCosto()
    .subscribe(
      data => {
        this.getCentroCosto = data;
      },
      error => this.toastr.error(error.message, 'Error!') );
}

mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        return valor ? res = "Si" : res = "No";
      }
      if(op == 2)
      {
        return valor ? res = "Activo" : res = "Inactivo";
      }
      
    }

    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }
}
