import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Agroquimico } from '../../models/Agroquimicos';
import { AgroquimicosService } from '../../services/agroquimicos/agroquimicos.service';

import { DetalleAgroquimico } from '../../models/DetalleAgroquimico';
import { DetalleAgroquimicoService } from '../../services/detalleAgroquimico/detalle-agroquimico.service';

import { TempIngActivo } from '../../models/TempIngActivo';


@Component({
  selector: 'app-agroquimicos',
  templateUrl: './agroquimicos.component.html',
  styleUrls: ['./agroquimicos.component.css']
})
export class AgroquimicosComponent implements OnInit {

    //configuracion del modal
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };

  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };


  pageActual: number = 1; //contador para la paginacion de la tabla de agroquimicos
  pActual: number = 1; //contador para la paginacion de la tabla detalles agroq. (ingredientes)

    //configuracion de la notificacion
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  
    //variables para obtener los filtros
  descripcionFilter: string = '';
  agroStatusFilter: number = 0;
  tipoAgroFilter: string = ' ';
  ingActivoFilter: string = '';

  //obtiene los id registrados por ultima vez en la tabla (se usa nomas el de agroquimico)
  ultimoRegAgro: string =' ';
  ultimoRegDeta: string = ' ';


  // obtiene los tipos de estados
  getEstado = [{ID: 1, Nombre: 'Solido', Tipo: 1}, {ID: 2, Nombre: 'Liquido', Tipo: 2},
  {ID: 3, Nombre: 'Gaseoso', Tipo: 3}];

// obtiene los tipos de estatus
getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
{ID: 3, Nombre: 'Inactivos', Tipo: 2}];

// obtiene los tipos de inventariable
getInventariable2 = [{ID: 1, Nombre: 'Si', Tipo: true}, {ID: 2, Nombre: 'No', Tipo: false}];

    ///PARA MANEJO DE LA TABLA AGROQUIMICOS
  agro:  Agroquimico = new  Agroquimico(); //para guardar lo capturado en la BD
  agros: any[] = []; //guarda la lista obtenida segun el filtrado

  ///PARA MANEJO DE LA TABLA DETALLES AGROQUIMICOS
  detalleAgro:  DetalleAgroquimico = new  DetalleAgroquimico(); //para guardar lo capturado en la BD
  detallesAgro: any[] = []; //guarda la lista obtenida segun el filtrado (no se usa pero aqui esta)

    ///PARA MANEJO DE LA TABLA DETALLES AGROQUIMICOS A TRAVEZ DE UNA TABLA TEMPORAL QUE SOLO EXISTE EN ANGULAR
  tempIngActivo: TempIngActivo = new TempIngActivo(); //para guardar lo capturado en la BD
  tempIngActivos:any[] = []; //se guardan los detalles dependiendo del id del agroquimico
  tempIngActivos2:any[] = []; //se guardan los nuevos ingredientes (detalles) cuando es editar

    //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
  nuevoItem = true;

  //si es inventariable se mostrara su valor y si no lo es sera 0
  mostrarExistencia = 0;
  mostrarCosto = 0;

  idAgroUpdate = 0; //obtiene el id del agroquimicos para buscar los detalles que tiene
  deleteAgroID = false; // para eliminar los detalles del agro

   //guardan la lista obtenida segun el tipo que se requiera
  getFormulacion:  any[] = [];
  getTipoAgro:  any[] = [];
  getIngActivo:  any[] = [];
  getTiposUnidades:  any[] = [];
  getTipoUnidadesTiempo:  any[] = [];

  cerrarIng = false; //arreglar bug (se ingresaba el ciclo cuando le daba en el boton cerrar)
  
/////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
deshabilitar: boolean = false;  


  constructor(private router: Router, private _userService: UsersService, 
    private _agroquimicoService: AgroquimicosService,
    private _detalleAgroService: DetalleAgroquimicoService,
    private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.GetgetTipoAgro();
    this.GetgetIngActivo();
  }

  onBuscar() {
    this.checkCombos();
    this._agroquimicoService.getLista(this.descripcionFilter,this.agroStatusFilter,this.tipoAgroFilter,this.ingActivoFilter).subscribe(
      (data: any) => {
        this.agros = data;
        
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
    
  }
  onBuscar2() {
    //this.checkCombos();
    this._detalleAgroService.getLista().subscribe(
      (data: any) => {
        this.detallesAgro = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
    
  }
  checkCombos()
  {
    if(this.tipoAgroFilter == null)
    {
      this.tipoAgroFilter = ' ';
    }
    if(this.ingActivoFilter == null)
    {
      this.ingActivoFilter = ' ';
    }
    if(this.agroStatusFilter == null)
    {
      this.agroStatusFilter = 0;
    }
  }

  onSubmit(FormData) {
    /// GUARDA TODO EL AGROQUIMICO JUNTO CON LA DE DETALLES
    if (FormData.valid) {
     
      //corrigiendo un bug
      if(this.agro.agroq_inventariable == null)
      {
        this.agro.agroq_inventariable = false;
      }
            
      //Guarda el agroquimico sin la de detalles
      this._agroquimicoService.guardar(this.agro)
    .subscribe(
      success => {
        this.toastr.success('Agroquímico guardado con exito.', 'Guardado!');
        this.onBuscar();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });

      this.getUltimoRegistroAgroquimicos(); //obtiene el ultimo id registrado en la tabla agroquimicos


      ////guardando el contenido del arreglo tempIngActivos que es la tabla hijo
      //de agroquimicos que es la tabla ingredientes en la de detalles agro.
      if(this.idAgroUpdate == 0) //si es igual a 0 es que es uno nuevo
      {
        let idAgro = parseInt(this.ultimoRegAgro,10);
        idAgro = idAgro+1; //le sumo uno para que sea el nuevo que se ingreso 
        for (let item of this.tempIngActivos)
          {
            this.detalleAgro.agroq_id = idAgro;
            this.detalleAgro.ingredAct_id = item.tempIngredAct_id;
            this.detalleAgro.detAgroq_concent = item.temp_concent;
            this.detalleAgro.unidad_ID = item.tempUnidad_ID;
            this.detalleAgro.detAgroq_status = true;
  
            this._detalleAgroService.guardar(this.detalleAgro,idAgro)
          .subscribe(
            success => {
              this.toastr.success('Detalle Agro guardado con exito.', 'Guardado!');
            },
            error => {
              this.toastr.error(error.message, 'Error!');
          });
      }
        }
        else //si es != de 0 es porque es un update
        {
          for (let item of this.tempIngActivos2)
          {
            this.detalleAgro.agroq_id = this.idAgroUpdate;
            this.detalleAgro.ingredAct_id = item.tempIngredAct_id;
            this.detalleAgro.detAgroq_concent = item.temp_concent;
            this.detalleAgro.unidad_ID = item.tempUnidad_ID;
            this.detalleAgro.detAgroq_status = true;
            this._detalleAgroService.guardar(this.detalleAgro,this.idAgroUpdate)
          .subscribe(
            success => {
              this.toastr.success('Detalle Agro guardado con exito.', 'Guardado!');
              this.idAgroUpdate= 0; //reinicio la variable
            },
            error => {
              this.toastr.error(error.message, 'Error!');
          });
        }
      }
      this.onBuscar();
      FormData.resetForm();
      this.modalRef.hide();
    }
    
  }

  getDetalleIDdelete(id: number)
  {
    this._detalleAgroService.getDetalleAgroquimicoID(id).subscribe(
      (data: any) => {

          for(let item in data)
          {
            this.onDeleteDetalles(data[item].detAgroq_ID);
          }
      }
    );
  }
  getUltimoRegistroAgroquimicos()
  {
    this._agroquimicoService.getUltimoRegistro().subscribe(
      (data: any) => {
        for(let item in data)
        {
          this.ultimoRegAgro = data[item].agrog_id;

        }
      }
    );
  }


  onSubmit2(FormData) {
    //para guardar el ingrediente en la tablita dentro de agroquimicos
if(!this.cerrarIng && this.tempIngActivo.tempIngredAct_id != null)
{
  this.toastr.success('Ingrediente agregado', 'Agregado!');
  if(this.idAgroUpdate == 0) //si no es un update se agregan normalmente (nuevo)
  {
   this.tempIngActivos.push(this.tempIngActivo);
  }
  else
  {
   this.tempIngActivos.push(this.tempIngActivo); //se agrega al normal para que aparesca en la tablita
   this.tempIngActivos2.push(this.tempIngActivo); //se agrega el nuevo que se agrego a la lista
  }
  

  this.getUltimoRegistroAgroquimicos();
  this.modalRef2.hide(); //al guardarse se oculta el modal de agregar ingrediente 
}
else
{
  this.cerrarIng=false;
}
      

     
  }
  
  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar el agroquímico, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        //ELIMINA LOS REGISTROS DE DETALLES AGROQUIMICOS
        this.getDetalleIDdelete(id);
        //ELIMINA LOS REGISTROS DE AGROQUIMICOS
        this.onDeleteAgroquimico(id);
        this.idAgroUpdate= 0;
        this.onBuscar();
      }
    });
  }
  
  onDeleteDetalles(id:number)
  {
    //elimina los registros de detalles con el detAgroq_ID
    this._detalleAgroService.eliminar(id)
        .subscribe(
          success => {
           ///trae los datos del detalle del agroquimico que contenga el id del agroquimico
    this._detalleAgroService.getDetalleAgroquimico(this.idAgroUpdate)
    .subscribe(
      data => {
        this.tempIngActivos = data; // en este caso se guardan en el arreglo los datos obtenidos de la BD segun el idAgro
      });
    
           
            Swal.fire({
              title: 'Eliminado!',
              text: 'Detalle a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
  }

  onDeleteAgroquimico(id:number)
  {
       ///ELIMINA LOS REGISTROS DE AGROQUIMICOS
       this._agroquimicoService.eliminar(id)
       .subscribe(
         success => {
           this.onBuscar();
           Swal.fire({
             title: 'Eliminado!',
             text: 'Agroquímico a sido eliminado con exito.',
             type: 'success',
             confirmButtonText: 'Aceptar'
           });
         },
         error => {
           this.toastr.error(error.message, 'Error!');
         });
  }

  onShow(id: number, template: TemplateRef<any>) {
    // muestra la info del agroqwuimico y la tabla de detalles agroquimicos

    //obtienen la info correspondiente que ira en los combos
    this.GetgetTipoAgro();
    this.GetgetFormulacion();
    this.GetTiposUnidades();
    this.GetgetTipoUnidadesTiempo();
    
    this.agro = new Agroquimico(); //se hace un nuevo objeto de agroquimicos
    this.tempIngActivos = []; //se inicializa en vacio el arreglo para llenar la tablita de detalles(ingredientes)

    if (id <= 0) {
      this.deshabilitar = false;
      this.nuevoItem = true;
      this.modalRef = this.modalService.show(template, this.config);
      this.agro.agroq_status = true;
      this.idAgroUpdate= 0;
    } else {
      //// Trae los datos del agroquimico segun su id
      this._agroquimicoService.getAgroquimico(id)
    .subscribe(
      data => {
        this.agro = data;
        this.idAgroUpdate = id;
        this.mostrarExistenciaCosto(this.agro);
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );


       ///trae los datos del detalle del agroquimico que contenga el id del agroquimico
    this._detalleAgroService.getDetalleAgroquimico(id)
    .subscribe(
      data => {
        this.tempIngActivos = data; // en este caso se guardan en el arreglo los datos obtenidos de la BD segun el idAgro
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
    
          
        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }

    this.tempIngActivos2 = []; //formatea el arreglo para tener solo los nuevos ingredientes en caso de ser un editar
   
  }

  onShow2(id: number, template: TemplateRef<any>) {
  //muestra el modal para agregar el ingrediente 
    this.tempIngActivo = new TempIngActivo();
    
    this.nuevoItem = true;
    this.modalRef2 = this.modalService.show(template, this.config);

  }

  mostrarExistenciaCosto(valor)
  {
      if(valor.agroq_inventariable == true)
      {
        this.mostrarExistencia = valor.agroq_existencia;
        this.mostrarCosto = valor.agroq_costoProm;
      }
      else
      {
        this.mostrarExistencia = 0;
        this.mostrarCosto = 0;
      }
   }

  GetTiposUnidades() {
  this._agroquimicoService.getTiposUnidades()
    .subscribe(
      data => {
        this.getTiposUnidades = data;
      },
      error => this.toastr.error(error.message, 'Error!') );
  }

  GetgetTipoUnidadesTiempo() {
    this._agroquimicoService.getUnidadesTiempo()
      .subscribe(
        data => {
          this.getTipoUnidadesTiempo = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
    }

  GetgetFormulacion() {
    this._agroquimicoService.getFormulaciones()
      .subscribe(
        data => {
          this.getFormulacion = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  GetgetTipoAgro() {
    this._agroquimicoService.getTipoAgroquimicos()
      .subscribe(
        data => {
          this.getTipoAgro = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  GetgetIngActivo() {
    this._agroquimicoService.getIngredienteActivo()
      .subscribe(
        data => {
          this.getIngActivo = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  getIngrediente(valor: any): string
  {

    //obtiene el nombre del ingrediente  para mostrarlo en la tabla dentro de agroquimicos
    var res = " ";

    if(valor != null)
    {
      for(let item of this.getIngActivo)
      {
          if (valor == item.ingredAct_id)
          {
             return res = item.ingredAct_desc;
          }
      }
    }
  }

  getUnidad(valor: any): string
  {
    //obtiene el nombre de la unidad para mostrarlo en la tabla dentro de agroquimicos
    var res = " ";
    if(valor != null)
    {
      for(let item of this.getTiposUnidades)
      {
          if (valor == item.unidad_id)
          {
             return res = item.unidad_desc;
          }
      }
    }
  }

  mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        if(valor)
        {
          return res = "Si"
        }
        else
        {
          return res = "No"
        }
      }
      if(op == 2)
      {
        if(valor)
        {
          return res = "Activo"
        }
        else
        {
          return res = "Inactivo"
        }
      }
      
    }
    
    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }
}
