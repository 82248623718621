import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { SemillasInsumosComponent } from './semillas-insumos/semillas-insumos.component';
import { UnidadesInsumosComponent } from './unidades-insumos/unidades-insumos/unidades-insumos.component';
import { LotesComponent } from './lotes/lotes.component';
import { ProductosInsumosComponent } from './productos-insumos/productos-insumos.component';
import { CicloComponent } from './ciclo/ciclo.component';
import { SiembraComponent } from './siembra/siembra.component';
import { FertilizantesComponent } from './fertilizantes/fertilizantes.component';
import { TipoAgroquimicoComponent } from '././tipo-agroquimico/tipo-agroquimico.component';
import { IngredientesActivosComponent } from './ingredientes-activos/ingredientes-activos.component';
import { AgroquimicosComponent } from './agroquimicos/agroquimicos.component';
import { GastosComponent } from './gastos/gastos.component';
import { UnidadTipoComponent } from './unidad-tipo/unidad-tipo.component';
import { PropiedadesComponent } from './propiedades/propiedades.component';
import { MaquinariaTransporteComponent} from './maquinaria-transporte/maquinaria-transporte.component';
import { MarcasComponent } from './marcas/marcas.component';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { FormulacionesComponent } from './formulaciones/formulaciones.component';
import { PuestosComponent } from './puestos/puestos.component';
import { TrabajadoresComponent } from './trabajadores/trabajadores.component';
import { ApGastosComponent } from './ap-gastos/ap-gastos.component';
import { CentroCostosComponent } from './centro-costos/centro-costos.component';
import { TipoGastoComponent } from './tipo-gasto/tipo-gasto.component';
import { ComprasComponent } from './compras/compras.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },
  {
    path: 'tiposusuario',
    component: TiposUsuarioComponent
  },
  {
    path: 'semillasInsumos',
    component: SemillasInsumosComponent
  },
  {
    path: 'unidadesInsumos',
    component: UnidadesInsumosComponent
  },
  {
    path: 'productosInsumos',
    component: ProductosInsumosComponent
  } ,
  {
    path: 'lotes',
    component: LotesComponent
  },
  {
    path: 'ciclos',
    component: CicloComponent
  },
  {
    path: 'siembra',
    component: SiembraComponent
  },
  {
    path: 'fertilizantes',
    component: FertilizantesComponent
  },
  {
    path: 'tipoAgroquimico',
    component: TipoAgroquimicoComponent
  },
  {
    path: 'ingredientesActivos',
    component: IngredientesActivosComponent
  },
  {
    path: 'agroquimicos',
    component: AgroquimicosComponent
  },
  {
    path: 'gastos',
    component: GastosComponent
  },
  {
    path: 'tiposUnidades',
    component: UnidadTipoComponent
  },
  {
    path: 'propiedades',
    component: PropiedadesComponent
  },
  {
     path: 'maquinaria',
  component: MaquinariaTransporteComponent
  },
  {
     path: 'marcas',
  component: MarcasComponent
  },
  {
     path: 'proveedor',
  component: ProveedorComponent
  },
  {
     path: 'formulaciones',
  component: FormulacionesComponent
  },
  {
     path: 'puestos',
  component: PuestosComponent
  },
  {
     path: 'trabajadores',
  component: TrabajadoresComponent
  },
  {
     path: 'apGastos',
  component: ApGastosComponent
  },
  {
     path: 'centroCostos',
  component: CentroCostosComponent
  },
  {
     path: 'tipoGasto',
  component: TipoGastoComponent
  },
  {
    path: 'compras',
    component: ComprasComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
