import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Semillas } from '../../models/Semillas';
import { SemillasInsumosService } from '../../services/semillasInsumos/semillas-insumos.service';

@Component({
  selector: 'app-semillas-insumos',
  templateUrl: './semillas-insumos.component.html',
  styleUrls: ['./semillas-insumos.component.css']
})
export class SemillasInsumosComponent implements OnInit {

  //configuracion del modal
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  pageActual: number = 1; //contador para la paginacion de la tabla

  //configuracion de la notificacion
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };

  //variables para obtener los filtros
  semillaFilter: string = '';
  semillaStatus: number = 0;
  semillaIn: number = 0;

  semillas: any[] = []; //guarda la lista obtenida segun el filtrado
  semilla: Semillas = new Semillas(); //para guardar lo capturado en la BD

  //si es inventariable se mostrara el valor correspondiente, si no lo es se mostrara en 0
  mostrarExistencia = 0;
  mostrarCosto = 0;

  //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
  nuevoItem = true;

  // obtiene los tipos de estatus
  getEstatus = [{ ID: 1, Nombre: 'Todos', Tipo: 0 }, { ID: 2, Nombre: 'Activos', Tipo: 1 },
  { ID: 3, Nombre: 'Inactivos', Tipo: 2 }];

  // obtiene los tipos de inventariable
  getInventariable = [{ ID: 1, Nombre: 'Todos', Tipo: 0 }, { ID: 2, Nombre: 'Si', Tipo: 1 },
  { ID: 3, Nombre: 'No', Tipo: 2 }];
  // obtiene los tipos de inventariable
  getInventariable2 = [{ ID: 1, Nombre: 'Si', Tipo: true }, { ID: 2, Nombre: 'No', Tipo: false }];

  tiposMedidas: any[] = [];
  tipoAbrev: any[] = [];

  /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
  deshabilitar: boolean = false;

  constructor(private router: Router, private _userService: UsersService, private _semillasService: SemillasInsumosService,
    private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
  }

  checkCombos() {
    if (this.semillaStatus == null) {
      this.semillaStatus = 0;
    }
    if (this.semillaIn == null) {
      this.semillaIn = 0;
    }
  }
  
  onBuscar() {
    this.checkCombos();
    this._semillasService.getLista(this.semillaFilter, this.semillaIn, this.semillaStatus).subscribe(
      (data: any) => {
        this.semillas = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._semillasService.guardar(this.semilla)
        .subscribe(
          success => {
            this.toastr.success('Semilla guardada con exito.', 'Guardado!');
            this.onBuscar();
            FormData.resetForm();
            this.modalRef.hide();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar la semilla, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._semillasService.eliminar(id)
          .subscribe(
            success => {
              this.onBuscar();
              Swal.fire({
                title: 'Eliminado!',
                text: 'Semilla a sido eliminada con exito.',
                type: 'success',
                confirmButtonText: 'Aceptar'
              });
            },
            error => {
              this.toastr.error(error.message, 'Error!');
            });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.getTiposMedidas();
    this.semilla = new Semillas();
    if (id <= 0) {
      this.deshabilitar = false;
      this.nuevoItem = true;
      this.modalRef = this.modalService.show(template, this.config);
      this.semilla.sem_status = true;
    } else {
      this._semillasService.getSemilla(id)
        .subscribe(
          data => {
            this.semilla = data;
            this.mostrarExistenciaCosto(this.semilla);
            this.modalRef = this.modalService.show(template, this.config);
          },
          error => this.toastr.error(error.message, 'Error!'));


      ////HABILITA O NO el campo de activo/inactivo 
      //porque ya existe uno en el caso de cuando es un nuevo item o un editar
      if (this.deshabilitar) {
        this.nuevoItem = true;
      }
      else {
        this.deshabilitar = false;
        this.nuevoItem = false;
      }
    }
  }


  mostrarExistenciaCosto(valor) {
    if (valor.sem_inventariable == true) {
      this.mostrarExistencia = valor.sem_existencia;
      this.mostrarCosto = valor.sem_costoProm;
    }
    else {
      this.mostrarExistencia = 0;
      this.mostrarCosto = 0;
    }
  }
  getTiposMedidas() {
    this._semillasService.getTiposMedidas()
      .subscribe(
        data => {
          this.tiposMedidas = data;
        },
        error => this.toastr.error(error.message, 'Error!'));
  }

  mostrarToF(valor: boolean, op: number): string {
    var res = "";
    if (op == 1) {
      if (valor) {
        return res = "Si"
      }
      else {
        return res = "No"
      }
    }
    if (op == 2) {
      if (valor) {
        return res = "Activo"
      }
      else {
        return res = "Inactivo"
      }
    }

  }

  cerrar() {
    this.router.navigate(['/dashboard']);
  }
}
