import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { Agroquimico } from '../../models/Agroquimicos';


@Injectable({
  providedIn: 'root'
})
export class AgroquimicosService {
  private _url = `${AppSettings.API_ENDPOINT}/Agroquimicos`;
  private _getLista = `${this._url}/Lista`;
  private _getUltimoRegistro = `${this._url}/UltimoRegistro`;
  private _getGetAgroquimico = `${this._url}/GetAgroquimico`;
  private _getTiposUnidades = `${this._url}/TiposUnidades`;
  private _getTipoAgroquimicos = `${this._url}/TipoAgroquimicos`;
  private _getFormulaciones = `${this._url}/Formulaciones`;
  private _getIngredienteActivo = `${this._url}/IngredienteActivo`;
  private _getUnidadesTiempo = `${this._url}/UnidadesTiempo`;
  private _guardar = `${this._url}/Guardar`;
  private _eliminar = `${this._url}/Eliminar`;

  constructor(public _http: HttpClient ,private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(nombre: String, estatus: number, tipo: String, ingActivo: String): Observable<any[]> {
    const params = new HttpParams().set('nombre', nombre.toString())
                                .set('estatus',estatus.toString())
                                .set('tipo',tipo.toString())
                                .set('ingActivo',ingActivo.toString());
    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getUltimoRegistro(): Observable<any[]> {
    return this._http.get<any[]>(this._getUltimoRegistro, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getAgroquimico(id: number): Observable<Agroquimico>  {
    return this._http.get<Agroquimico>(`${this._getGetAgroquimico}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getTiposUnidades(): Observable<any[]> {
    return this._http.get<any[]>(this._getTiposUnidades, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getTipoAgroquimicos(): Observable<any[]> {
    return this._http.get<any[]>(this._getTipoAgroquimicos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getUnidadesTiempo(): Observable<any[]> {
    return this._http.get<any[]>(this._getUnidadesTiempo, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getFormulaciones(): Observable<any[]> {
    return this._http.get<any[]>(this._getFormulaciones, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }
  getIngredienteActivo(): Observable<any[]> {
    return this._http.get<any[]>(this._getIngredienteActivo, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  guardar(_agro: any): Observable<Agroquimico> {
    return this._http.post<Agroquimico>(`${this._guardar}`, _agro, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  eliminar(id: number) {
      return this._http.delete(`${this._eliminar}/${id}`, { headers: this._userService.header}).pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
