import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Puestos } from '../../models/Puestos';
import { PuestosService } from '../../services/puestos/puestos.service';


@Component({
  selector: 'app-puestos',
  templateUrl: './puestos.component.html',
  styleUrls: ['./puestos.component.css']
})
export class PuestosComponent implements OnInit {

    //configuracion del modal
    @ViewChild('editModal') editModal: ModalDirective;
    modalRef: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
    };
    pageActual: number = 1; //contador para la paginacion de la tabla
    
     //configuracion de la notificacion
    public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
    
      //variables para obtener los filtros
    nombreFilter: string = '';
    puestoStatus: number = 0;
    puestoTipo: number = 0;
  
    puestos: any[] = [];//guarda la lista obtenida segun el filtrado
    puesto: Puestos = new Puestos(); //para guardar lo capturado en la BD

  
      //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
    nuevoItem = true;
  
    // obtiene los tipos de estatus
    getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
    {ID: 3, Nombre: 'Inactivos', Tipo: 2}];

     // obtiene los tipos para el filtro
     getTipoFilter = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Oficina', Tipo: 4},
     {ID: 3, Nombre: 'Campo', Tipo: 5}];
  
     // obtiene los tipos para el alta
     getTipo = [{ID: 1, Nombre: 'Oficina', Tipo: 4},
     {ID: 2, Nombre: 'Campo', Tipo: 5}];
  
    /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
    deshabilitar: boolean = false;  
  
    constructor(private router: Router,private _userService: UsersService, private _puestosService: PuestosService, 
      private modalService: BsModalService, private toastr: ToastrService) {
      this._userService.loadStorage();
    }

  ngOnInit() {
    this.onBuscar();
  }
  checkBox()
  {
    if(this.puestoStatus == null)
    {
      this.puestoStatus = 0;
    }
    if(this.puestoTipo == null)
    {
      this.puestoTipo = 0;
    }
  }
  onBuscar() {
    
    this.checkBox();
    this._puestosService.getLista(this.nombreFilter,this.puestoTipo,this.puestoStatus).subscribe(
      (data: any) => {
        this.puestos = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }
  onSubmit(FormData) {
    if (FormData.valid) {
      this._puestosService.guardar(this.puesto)
    .subscribe(
      success => {
        this.toastr.success('Puesto guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar el puesto, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._puestosService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Puesto a sido eliminada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.puesto = new Puestos();
    if (id <= 0) {
      this.deshabilitar = false;
      this.modalRef = this.modalService.show(template, this.config);
      this.puesto.puesto_status = true;
      this.nuevoItem=true;
      
    } else {
      this._puestosService.getPuesto(id)
    .subscribe(
      data => {
        this.puesto = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );

        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }
    }
  }

  mostrarToF(valor: boolean, op: number): string
  {
    var res = "";
    if(op == 1)
    {
      return valor ? res = "Si" : res = "No";
    }
    if(op == 2)
    {
      return valor ? res = "Activo" : res = "Inactivo";
    }
    
  }

  mostrarTipo(valor: number): string
  {

    return valor == 4  ? "Oficina" : "Campo";
    
  }
  cerrar()
  {
    this.router.navigate(['/dashboard']);
  }

}
