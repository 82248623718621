export class Siembra {

    siembra_id: number;
    siembra_loteID ?: number;
    siembra_semillaID ?: number;
    siembra_cicloID ?: number;
    siembra_descripcion : string;
    siembra_fechaIni ?: Date;
    siembra_fechaFin ?: Date;
    siembra_status?: boolean;
    siembra_obs: string;  
}
