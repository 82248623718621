export class Proveedor {
    prov_id : number;
    prov_nomCom : string;
    prov_razonSoc : string;
    prov_contacto : string;
    prov_dir : string;
    prov_col : string;
    prov_cp : string;
    prov_ref : string;
    prov_cd : string;
    prov_edo : string;
    prov_URL : string;
    prov_email : string;
    prov_tel : string;
    prov_cel : string;
    prov_status : boolean;
    prov_obs : string;
  }