import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Propiedades } from '../../models/Propiedades';
import { PropiedadesService } from '../../services/propiedades/propiedades.service';

@Component({
  selector: 'app-propiedades',
  templateUrl: './propiedades.component.html',
  styleUrls: ['./propiedades.component.css']
})
export class PropiedadesComponent implements OnInit {

   //configuracion del modal
   @ViewChild('editModal') editModal: ModalDirective;
   modalRef: BsModalRef;
   config = {
     backdrop: true,
     ignoreBackdropClick: true,
   };
   pageActual: number = 1; //contador para la paginacion de la tabla
   
    //configuracion de la notificacion
   public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
   
     //variables para obtener los filtros
   nombreFilter: string = '';
   statusFilter: number = 0;
   tipoFilter: number = 0;
   centroCostoFilter = null;
   tipoGastoFilter = null;

   propiedades: any[] = [];//guarda la lista obtenida segun el filtrado
   propiedad: Propiedades = new Propiedades(); //para guardar lo capturado en la BD
 
 //obtiene las unidades de la tabla unidades 
   tiposUnidades: any[] = [];
   getCentroCosto: any[] = []; //guarda la lista obtenida segun el filtrado
  getTipoGasto: any[] = []; //guarda la lista obtenida segun el filtrado

     //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
   nuevoItem = true;
 
     //////variables para obtenerlos
  montoAdep = 0;
  periodos = 0;
  /////VARIABLES CALCULOS - Calculo1 = monto por periodo , Calculo2 = Importe a depreciar
  calculo1 = 0;
    
  calculo2 = 0

   // obtiene los tipos de estatus
   getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
   {ID: 3, Nombre: 'Inactivos', Tipo: 2}];

   /*
   // obtiene los tipos 
   getTipos = [{ID: 1, Nombre: 'Seleccione un tipo', Tipo: 0}, {ID: 2, Nombre: 'Oficina', Tipo: 4},
   {ID: 3, Nombre: 'Campo', Tipo: 5}];
 */
   /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
   deshabilitar: boolean = false;  


   constructor(private router: Router,private _userService: UsersService, private _propiedadesService: PropiedadesService, 
     private modalService: BsModalService, private toastr: ToastrService) {
     this._userService.loadStorage();
   }

   ngOnInit() {
    this.onBuscar();
    this.GetgetCentroCosto();
    this.GetgetTipoGasto();
  }
 
  checkCombos()
  {
    if(this.statusFilter == null)
    {
      this.statusFilter = 0;
    }
    if(this.centroCostoFilter == null)
    {
      this.centroCostoFilter = null;
    }
    if(this.tipoGastoFilter == null)
    {
      this.tipoGastoFilter = null;
    }
  }
  onBuscar() {
    this.checkCombos();
    this._propiedadesService.getLista(this.nombreFilter,this.centroCostoFilter,this.tipoGastoFilter, this.statusFilter).subscribe(
      (data: any) => {
        this.propiedades = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._propiedadesService.guardar(this.propiedad)
    .subscribe(
      success => {
        this.toastr.success('Propiedad guardada con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar la propiedad, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._propiedadesService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Propiedad a sido eliminada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.getTiposUnidad();
    this.propiedad = new Propiedades();
    if (id <= 0) {
      this.deshabilitar = false;
      this.modalRef = this.modalService.show(template, this.config);
      this.propiedad.propiedad_status = true;
      this.nuevoItem=true;
      
    } else {
      this._propiedadesService.getPropiedad(id)
    .subscribe(
      data => {
        this.propiedad = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );

        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }
    }
  }

  getTiposUnidad() {
    this._propiedadesService.getTiposUnidades()
      .subscribe(
        data => {
          this.tiposUnidades = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
    }
    GetgetCentroCosto() {
      this._propiedadesService.getCentroCosto()
        .subscribe(
          data => {
            this.getCentroCosto = data;
          },
          error => this.toastr.error(error.message, 'Error!') );
    }
  
    GetgetTipoGasto() {
      this._propiedadesService.getTipoGasto()
        .subscribe(
          data => {
            this.getTipoGasto = data;
          },
          error => this.toastr.error(error.message, 'Error!') );
    }

    Calculo1(): number
    {
      //calculo de Monto por período
      
      var res = this.montoAdep/ this.periodos;
      this.calculo1 = parseFloat(res.toFixed(2));
      if(this.calculo1 >=0)
      {
        return this.calculo1;
      }

      return this.calculo1 = 0;
     
    }
    Calculo2()
    {
      //calculo de Importe por depreciar
      var res = this.montoAdep - this.periodos;
      this.calculo2 = parseFloat(res.toFixed(2));
       
      return this.calculo2;
    }
    
    mostrarTipo(op: number): string
    {
      switch(op)
      {
          case 1: return "Maquinaria";
          case 2: return "Equipo y Transporte";
          case 3: return "Maquinaria-Equipo";
          case 4: return "Oficina";
          case 5: return "Campo";
          case 6: return "Oficina-Campo";
      }
    }

    mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        if(valor)
        {
          return res = "Si"
        }
        else
        {
          return res = "No"
        }
      }
      if(op == 2)
      {
        if(valor)
        {
          return res = "Activo"
        }
        else
        {
          return res = "Inactivo"
        }
      }
      
    }
    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }

}
