import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { MaquinariaTransporte } from '../../models/MaquinariaTransporte';

@Injectable({
    providedIn: 'root'
})
export class MaquinariaTransporteService {
    private _url = `${AppSettings.API_ENDPOINT}/Maquinaria`;
    private _getLista = `${this._url}/Lista`;
    private _getMaquinariaTransporte = `${this._url}/GetMaquinariaTransporte`;
    private _getMarcas = `${this._url}/Marcas`;
    private _guardar = `${this._url}/Guardar`;
    private _eliminar = `${this._url}/Eliminar`;

    constructor(public _http: HttpClient, private _userService: UsersService) {
        this._userService.loadStorage();
    }

    
    getLista(nombre: String, estatus: number, tipo: number, marca: string,depreciado:number): Observable<any[]> {
        const params = new HttpParams().set('nombre', nombre.toString())
                                        .set('estatus', estatus.toString())
                                        .set('tipo', tipo.toString())
                                        .set('marca', marca.toString())
                                        .set('depreciado', depreciado.toString());
        return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header })
            .pipe(
                tap(data => data),
                catchError(this.handleError)
            );
    }

    getMaquinariaTransporte(id: number): Observable<MaquinariaTransporte> {
        return this._http.get<MaquinariaTransporte>(`${this._getMaquinariaTransporte}/${id}`, { headers: this._userService.header })
            .pipe(
                tap(data => data),
                catchError(this.handleError)
            );
    }
    getMarcas(): Observable<any[]> {
        return this._http.get<any[]>(this._getMarcas, { params: null, headers: this._userService.header})
        .pipe(
          tap(data => data),
          catchError(this.handleError)
        );
      }
    guardar(_maquinariaTransporte: any): Observable<MaquinariaTransporte> {
        return this._http.post<MaquinariaTransporte>(`${this._guardar}`, _maquinariaTransporte, { headers: this._userService.header })
            .pipe(
                tap(data => data),
                catchError(this.handleError)
            );
    }

    eliminar(id: number) {
        return this._http.delete(`${this._eliminar}/${id}`, { headers: this._userService.header }).pipe(
            tap(data => data),
            catchError(this.handleError)
        );
    }

    // Handdle Error methor for observale
    private handleError(err: HttpErrorResponse) {
        return throwError(err.error);
    }
}