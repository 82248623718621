export class MaquinariaTransporte {
    maqEquipo_id: number;
    maqEquipo_desc: string;
    maqEquipo_tipo: boolean;
    maqEquipo_marca: number;
    maqEquipo_modelo: string;
    maqEquipo_anio: string;
    maqEquipo_importe: number;
    maqEquipo_perADeprec: number;
    maqEquipo_impPeriodo: number;
    maqEquipo_perDepreciados: number;
    maqEquipo_impDeprec: number;
    maqEquipo_impPorDep: number;
    maqEquipo_depreciado: boolean;
    maqEquipo_status: boolean;
    maqEquipo_obs: string;

    constructor()
    {
      this.maqEquipo_perDepreciados = 0;
      this.maqEquipo_impDeprec = 0;
      this.maqEquipo_depreciado = false;
    }
}
