import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// Modules
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

// Directives
import { DatepickerToggleDirective } from '../directives/datepicker-toggle.directive';

// Pagination
import {NgxPaginationModule} from 'ngx-pagination';
// Routes
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { SemillasInsumosComponent } from './semillas-insumos/semillas-insumos.component';
import { UnidadesInsumosComponent } from './unidades-insumos/unidades-insumos/unidades-insumos.component';
import { LotesComponent } from './lotes/lotes.component';
import { ProductosInsumosComponent } from './productos-insumos/productos-insumos.component';
import { CicloComponent } from './ciclo/ciclo.component';
import {NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SiembraComponent } from './siembra/siembra.component';
import { FertilizantesComponent } from '././fertilizantes/fertilizantes.component';
import { TipoAgroquimicoComponent } from './tipo-agroquimico/tipo-agroquimico.component';
import { IngredientesActivosComponent } from './ingredientes-activos/ingredientes-activos.component';
import { AgroquimicosComponent } from './agroquimicos/agroquimicos.component';
import { GastosComponent } from './gastos/gastos.component';
import { UnidadTipoComponent } from './unidad-tipo/unidad-tipo.component';
import { PropiedadesComponent } from './propiedades/propiedades.component';
import { MaquinariaTransporteComponent } from './maquinaria-transporte/maquinaria-transporte.component';
import { MarcasComponent } from './marcas/marcas.component';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { FormulacionesComponent } from './formulaciones/formulaciones.component';
import { PuestosComponent } from './puestos/puestos.component';
import { TrabajadoresComponent } from './trabajadores/trabajadores.component';
import { ApGastosComponent } from './ap-gastos/ap-gastos.component';
import { CentroCostosComponent } from './centro-costos/centro-costos.component';
import { TipoGastoComponent } from './tipo-gasto/tipo-gasto.component';
import { ComprasComponent } from './compras/compras.component';

@NgModule({
    declarations: [
        DashboardComponent,
        UsuariosComponent,
        TiposUsuarioComponent,
        DatepickerToggleDirective,
        SemillasInsumosComponent,
        UnidadesInsumosComponent,
        LotesComponent,
        ProductosInsumosComponent,
        CicloComponent,
        SiembraComponent,
        FertilizantesComponent,
        TipoAgroquimicoComponent,
        IngredientesActivosComponent,
        AgroquimicosComponent,
        GastosComponent,
        UnidadTipoComponent,
        PropiedadesComponent,
        MaquinariaTransporteComponent,
        MarcasComponent,
        ProveedorComponent,
        FormulacionesComponent,
        PuestosComponent,
        TrabajadoresComponent,
        ApGastosComponent,
        CentroCostosComponent,
        TipoGastoComponent,
        ComprasComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PagesRoutingModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgbModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgxMaskModule.forRoot(),
        NgxPaginationModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class PagesModule {}
