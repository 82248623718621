export class Agroquimico {
    agroq_id: number;
    agroq_nomComercial: string;
    tipo_insumo?: number;
    agroq_estado?: number;
    agroq_tipo?: number;
    agroq_formulacion ?:number;
    agroq_unidad ?: number;
    agroq_descInertes : string;
    agroq_concInertes ?: number;
    agroq_registro : string;
    agroq_reentrada ?: number;
    agroq_unidadReent ?: number;
    agroq_intervSeg ?: number;
    agroq_unidadSeg ?: number;
    agroq_costoProm?: number;
    agroq_existencia?: number;
    agroq_status ?: boolean;
    agroq_inventariable ?: boolean;
    agroq_obs: string;
    agroq_ingActivo ?: number;

    
    constructor()
    {
      this.tipo_insumo = 3;
      this.agroq_existencia = 0;
      this.agroq_costoProm = 0;
    }
    
  }
  