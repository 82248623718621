import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Trabajadores } from '../../models/Trabajadores';
import { TrabajadoresService } from '../../services/trabajadores/trabajadores.service';


@Component({
  selector: 'app-trabajadores',
  templateUrl: './trabajadores.component.html',
  styleUrls: ['./trabajadores.component.css']
})
export class TrabajadoresComponent implements OnInit {

    //configuracion del modal
    @ViewChild('editModal') editModal: ModalDirective;
    modalRef: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
    };
    pageActual: number = 1; //contador para la paginacion de la tabla
    
     //configuracion de la notificacion
    public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
    
      //variables para obtener los filtros
    nombreFilter: string = '';
    trabStatus: number = 0;
    trabTipo: number = 0;
    trabPuesto : string = ' ';
    centroCostoFilter: string = '';
    tipoGastoFilter: string = ' ';
  
    trabs: any[] = [];//guarda la lista obtenida segun el filtrado
    trab: Trabajadores = new Trabajadores(); //para guardar lo capturado en la BD
  

  
      //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
    nuevoItem = true;
  
    // obtiene los tipos de estatus
    getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
    {ID: 3, Nombre: 'Inactivos', Tipo: 2}];

    
    // obtiene los tipos para el filtro
    getTipoPuesto = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Maquinaria', Tipo: 4},
    {ID: 3, Nombre: 'Campo', Tipo: 5}];
 
    // obtiene los tipos de puesto para el alta
    getTipo = [{ID: 1, Nombre: 'Oficina', Tipo: 4},
    {ID: 2, Nombre: 'Campo', Tipo: 5}];
    
    getCentroCosto: any[] = []; //guarda la lista obtenida segun el filtrado
    getTipoGasto: any[] = []; //guarda la lista obtenida segun el filtrado
      //obtiene las unidades de la tabla unidades 
      getPuesto: any[] = [];

    /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
    deshabilitar: boolean = false;  
  
    constructor(private router: Router,private _userService: UsersService, private _trabService: TrabajadoresService, 
      private modalService: BsModalService, private toastr: ToastrService) {
      this._userService.loadStorage();
    }
  

  ngOnInit() {
    this.onBuscar();
    this.getPuestos();
    this.GetgetCentroCosto();
    this.GetgetTipoGasto();
  }
  
  checkCombos()
  {

    if(this.trabStatus == null)
    {
      this.trabStatus = 0;
    }
    if(this.trabPuesto == null)
    {
      this.trabPuesto = ' ';
    }
    if(this.centroCostoFilter == null)
    {
      this.centroCostoFilter = ' ';
    }
    if(this.tipoGastoFilter == null)
    {
      this.tipoGastoFilter = ' ';
    }
  }
  onBuscar() {
   
    this.checkCombos();
   // console.log(this.nombreFilter,this.trabTipo,this.trabPuesto, this.trabStatus);
    this._trabService.getLista(this.nombreFilter,this.centroCostoFilter,this.tipoGastoFilter,this.trabPuesto, this.trabStatus).subscribe(
      (data: any) => {
        this.trabs = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      console.log("ID DEL CENTRO COSTO "+ this.trab.trabajador_centroCostoID);
      this._trabService.guardar(this.trab)
    .subscribe(
      success => {
        this.toastr.success('Trabajador guardado con exito.', 'Guardado!');
        this.onBuscar();
        this.getPuestos();
        this.GetgetCentroCosto();
    this.GetgetTipoGasto();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar al trabajador, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._trabService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Trabajador a sido eliminada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.getPuestos();
    this.GetgetCentroCosto();
    this.GetgetTipoGasto();
    this.trab = new Trabajadores();
    if (id <= 0) {
      this.deshabilitar = false;
      this.modalRef = this.modalService.show(template, this.config);
      this.trab.trabajador_status = true;
      this.nuevoItem=true;
      
    } else {
      this._trabService.getTrabajador(id)
    .subscribe(
      data => {
        this.trab = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );

        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }
    }
  }

  getPuestos() {
    this._trabService.getPuestos()
      .subscribe(
        data => {
          this.getPuesto = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
    }

  GetgetCentroCosto() {
    this._trabService.getCentroCosto()
      .subscribe(
        data => {
          this.getCentroCosto = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  GetgetTipoGasto() {
    this._trabService.getTipoGasto()
      .subscribe(
        data => {
          this.getTipoGasto = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }
    mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        return valor ? res = "Si" : res = "No";
      }
      if(op == 2)
      {
        return valor ? res = "Activo" : res = "Inactivo";
      }
      
    }

    mostrarTipo(valor: number): string
  {

    return valor == 4  ? "Oficina" : "Campo";
    
  }
    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }
}
