import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Formulaciones } from '../../models/Formulaciones';
import { FormulacionesService } from '../../services/formulaciones/formulaciones.service';



@Component({
  selector: 'app-formulaciones',
  templateUrl: './formulaciones.component.html',
  styleUrls: ['./formulaciones.component.css']
})
export class FormulacionesComponent implements OnInit {

   //configuracion del modal
   @ViewChild('editModal') editModal: ModalDirective;
   modalRef: BsModalRef;
   config = {
     backdrop: true,
     ignoreBackdropClick: true,
   };
   pageActual: number = 1; //contador para la paginacion de la tabla
   
    //configuracion de la notificacion
   public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
   
     //variables para obtener los filtros
   nombreFilter: string = '';
   formuStatus: number = 0;
 
   forms: any[] = [];//guarda la lista obtenida segun el filtrado
   form: Formulaciones = new Formulaciones(); //para guardar lo capturado en la BD
 
     //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
   nuevoItem = true;
 
   // obtiene los tipos de estatus
   getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
   {ID: 3, Nombre: 'Inactivos', Tipo: 2}];
 
   /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
   deshabilitar: boolean = false;  
 
   constructor(private router: Router,private _userService: UsersService, private _formuService: FormulacionesService, 
     private modalService: BsModalService, private toastr: ToastrService) {
     this._userService.loadStorage();
   }

  ngOnInit() {
    this.onBuscar();
  }

  onBuscar() {
    if(this.formuStatus == null)
  {
    this.formuStatus = 0;
  }
    this._formuService.getLista(this.nombreFilter,this.formuStatus).subscribe(
      (data: any) => {
        this.forms = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._formuService.guardar(this.form)
    .subscribe(
      success => {
        this.toastr.success('Formulación guardada con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar la formulación, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._formuService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Formulación a sido eliminada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.form = new Formulaciones();
    if (id <= 0) {
      this.deshabilitar = false;
      this.modalRef = this.modalService.show(template, this.config);
      this.form.formu_status = true;
      this.nuevoItem=true;
      
    } else {
      this._formuService.getFormu(id)
    .subscribe(
      data => {
        this.form = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );

        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }
    }
  }

  mostrarToF(valor: boolean, op: number): string
  {
    var res = "";
    if(op == 1)
    {
      return valor ? res = "Si" : res = "No";
    }
    if(op == 2)
    {
      return valor ? res = "Activo" : res = "Inactivo";
    }
    
  }
  cerrar()
  {
    this.router.navigate(['/dashboard']);
  }

}
