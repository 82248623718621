import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { Proveedor } from '../../models/Proveedor';
import { ProveedorService } from '../../services/proveedor/proveedor.service';


@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent implements OnInit {

    //configuracion del modal
    @ViewChild('editModal') editModal: ModalDirective;
    modalRef: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
    };
    pageActual: number = 1; //contador para la paginacion de la tabla
    
     //configuracion de la notificacion
    public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
    
      //variables para obtener los filtros
    nombreFilter: string = '';
    provStatus: number = 0;
    razonS : string = '';
  
    provs: any[] = [];//guarda la lista obtenida segun el filtrado
    prov: Proveedor = new Proveedor(); //para guardar lo capturado en la BD
  
      //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
    nuevoItem = true;
  
    // obtiene los tipos de estatus
    getEstatus = [{ID: 1, Nombre: 'Todos', Tipo: 0}, {ID: 2, Nombre: 'Activos', Tipo: 1},
    {ID: 3, Nombre: 'Inactivos', Tipo: 2}];
  
    /////VARIABLE PARA DESHABLITAR LOS INPUTS EN CASO DE SER EL BOTON VER (true=deshabilitar | false = ver)
    deshabilitar: boolean = false;  
  
    constructor(private router: Router,private _userService: UsersService, private _provService: ProveedorService, 
      private modalService: BsModalService, private toastr: ToastrService) {
      this._userService.loadStorage();
    }

  ngOnInit() {
    this.onBuscar();
  }

  onBuscar() {
    if(this.provStatus == null)
  {
    this.provStatus = 0;
  }
    this._provService.getLista(this.nombreFilter,this.razonS, this.provStatus).subscribe(
      (data: any) => {
        this.provs = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._provService.guardar(this.prov)
    .subscribe(
      success => {
        this.toastr.success('Proveedor guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar al proveedor, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._provService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Proveedor a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.prov = new Proveedor();
    if (id <= 0) {
      this.deshabilitar = false;
      this.modalRef = this.modalService.show(template, this.config);
      this.prov.prov_status = true;
      this.nuevoItem=true;
      
    } else {
      this._provService.getProveedor(id)
    .subscribe(
      data => {
        this.prov = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );

        ////HABILITA O NO el campo de activo/inactivo 
        //porque ya existe uno en el caso de cuando es un nuevo item o un editar
        if(this.deshabilitar)
        {
          this.nuevoItem = true;
        }
        else
        {
          this.deshabilitar = false;
          this.nuevoItem = false;
        }
    }
  }

  mostrarToF(valor: boolean, op: number): string
    {
      var res = "";
      if(op == 1)
      {
        return valor ? res = "Si" : res = "No";
      }
      if(op == 2)
      {
        return valor ? res = "Activo" : res = "Inactivo";
      }
      
    }
    cerrar()
    {
      this.router.navigate(['/dashboard']);
    }


}
