import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class CompraProducto {
  COM_ID: number;
  COM_FolioFactura: string;
  COM_FechaCreacion: any;
  COM_FechaFactura: any;
  COM_Observaciones: string;
  COM_Status: string;
  COM_Subtotal: number;
  COM_Descuento: number;
  COM_Impuestos: number;
  COM_Total: number;
  COM_DatosAdicionales: string;
  ID_Ciclo?: number;
  
  IMR: number;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.COM_ID = 0;
    this.COM_Observaciones = '';
    this.COM_FechaCreacion = new Date();
    this.COM_FechaFactura = new Date();
    this.COM_Status = 'G';
    this.SelectedDate = {
        day: this.COM_FechaFactura.getDate(),
        month: this.COM_FechaFactura.getMonth() + 1,
        year: this.COM_FechaFactura.getFullYear() };
  }
}
