import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class AplicacionDepreciacion {
    apliDep_ID : number;
    apliDep_apliGastoID : number;
    apliDep_tipoActivo : boolean;
    apliDep_maqEquipoId : number;
    apliDep_importe : number;
    apliDep_periodo : number;
    apliDep_status : boolean;
  constructor() {
    this.apliDep_ID = 0;
    this.apliDep_tipoActivo = null;
    this.apliDep_maqEquipoId = null;
    this.apliDep_importe = 0;
  }
  }
  