import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class AplicacionGastos {
    apliGasto_ID : number;
    apliGasto_centroCostoID : number;
    apliGasto_tipoGasto : number;
    apliGasto_gastoID : number;
    apliGasto_desc : string ;
    apliGasto_importe : number;
    apliGasto_fechaApli : Date;
    apliGasto_fechaCapt : Date;
    apliGasto_status : boolean;
    SelectedDate: NgbDateStruct;
  constructor() {
    this.apliGasto_ID = 0;
    this.apliGasto_fechaApli = new Date();
    this.apliGasto_centroCostoID = null;
    this.apliGasto_tipoGasto = null;
    this.apliGasto_gastoID = null;
    this.apliGasto_desc = '';
    this.apliGasto_importe = 0;
    this.SelectedDate = {
        day: this.apliGasto_fechaApli.getDate(),
        month: this.apliGasto_fechaApli.getMonth() + 1,
        year: this.apliGasto_fechaApli.getFullYear() };
  }
  }
  