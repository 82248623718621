import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { MaquinariaTransporte } from '../../models/MaquinariaTransporte';
import { MaquinariaTransporteService } from '../../services/MaquinariaTransporte/maquinariaTransporte.service';

@Component({
  selector: 'app-maquinaria-transporte',
  templateUrl: './maquinaria-transporte.component.html',
  styleUrls: ['./maquinaria-transporte.component.css']
})
export class MaquinariaTransporteComponent implements OnInit {

  // configuracion del modal
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  pageActual: number = 1; // contador para la paginacion de la tabla

  // configuracion de la notificacion
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };

  // variables para obtener los filtros
  maquinariaTransporteFilter: string = '';
  maquinariaTransporteStatus: number = 0;
  maquinariaTransporteTipo : number =0;
  maquinariaTransporteMarca :string = '';
  maquinariaTransporteDepreciados: number=0;

  close = false; //arreglar bug (se ingresaba el ciclo cuando le daba en el boton cerrar)

  maquinariasTransportes: any[] = []; // guarda la lista obtenida segun el filtrado
  maquinariaTransporte: MaquinariaTransporte = new MaquinariaTransporte(); // para guardar lo capturado en la

  //si es inventariable se mostrara el valor correspondiente, si no lo es se mostrara en 0
  mostrarExistencia = 0;
  mostrarCosto = 0;

  //deshabilitar el activo/inactivo cuando se da de alta uno nuevo
  nuevoItem = true;

  //////variables para obtenerlos
  montoAdep = 0;
  periodos = 0;
  /////VARIABLES CALCULOS - Calculo1 = monto por periodo , Calculo2 = Importe a depreciar
  calculo1 = 0;
    
  calculo2 = 0

  // obtiene los tipos de estatus
  getEstatus = [{ ID: 1, Nombre: 'Todos', Tipo: 0 }, { ID: 2, Nombre: 'Activos', Tipo: 1 },
  { ID: 3, Nombre: 'Inactivos', Tipo: 2 }];


  // obtiene los tipos Maquinaria o Equipo de transporte
  getTipo = [{ ID: 1, Nombre: 'Todos', Tipo: 0 },{ ID: 2, Nombre: 'Maquinaria', Tipo: 1 }, { ID: 3, Nombre: 'Equipo de transporte', Tipo: 2 }];
  // obtiene los tipos Maquinaria o Equipo de transporte
  getTipoAlta = [{ ID: 1, Nombre: 'Maquinaria', Tipo: true }, { ID: 2, Nombre: 'Equipo de transporte', Tipo: false }];

  getDepreciado = [{ ID: 1, Nombre: 'Todos', Tipo: 0 },{ ID: 2, Nombre: 'Si', Tipo: 1 }, { ID: 3, Nombre: 'No', Tipo: 2 }];

  getMarcas: any[] = [];

  deshabilitar: false;

  constructor(
    private router: Router, private _userService: UsersService, private _maquinariaTransporteService: MaquinariaTransporteService,
    private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
     this.onBuscar();
     this.getGetMarcas();
  }

  checkCombos() {
    if (this.maquinariaTransporteStatus == null) {
      this.maquinariaTransporteStatus = 0;
    }
    if (this.maquinariaTransporteTipo == null) {
      this.maquinariaTransporteTipo = 0;
    }
    if (this.maquinariaTransporteDepreciados == null) {
      this.maquinariaTransporteDepreciados = 0;
    }
    if (this.maquinariaTransporteMarca == null) {
      this.maquinariaTransporteMarca = '';
    }
  }

  enConstruccion()
  {
    Swal.fire('Módulo en construcción ♥')
  }
  onBuscar() {
    this.checkCombos();
    this._maquinariaTransporteService.getLista(this.maquinariaTransporteFilter,this.maquinariaTransporteStatus,this.maquinariaTransporteTipo,this.maquinariaTransporteMarca,this.maquinariaTransporteDepreciados).subscribe(
      (data: any) => {
        this.maquinariasTransportes = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    ); 
  }

  onSubmit(FormData) {
    if(!this.close)
      { 
    if (FormData.valid) {

      ////guardamos los calculos en su campo correspondiente
      this.maquinariaTransporte.maqEquipo_impPeriodo = this.calculo1;
      this.maquinariaTransporte.maqEquipo_impPorDep = this.calculo2;
       ///oguardamos las variavariables con las que se hacen los calculos
       this.maquinariaTransporte.maqEquipo_importe = this.montoAdep;
       this.maquinariaTransporte.maqEquipo_perADeprec = this.periodos;

      this._maquinariaTransporteService.guardar(this.maquinariaTransporte)
        .subscribe(
          success => {
            this.toastr.success('La Maquinaria o Transporte ha sido guardada con exito.', 'Guardado!');
            this.onBuscar();
            FormData.resetForm();
            this.modalRef.hide();
            this.calculo1 = 0;
            this.calculo2 =0;
            this.montoAdep = 0;
            this.periodos = 0;
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
    }
  }else
  {
    this.close = false;
  }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.maquinariaTransporte = new MaquinariaTransporte();
    if (id <= 0) {
      this.deshabilitar = false;
      this.nuevoItem = true;
      this.calculo1 = 0;
      this.calculo2 =0;
      this.montoAdep = 0;
      this.periodos = 0;
      this.modalRef = this.modalService.show(template, this.config);
      this.maquinariaTransporte.maqEquipo_status = true;
    } else {
      this._maquinariaTransporteService.getMaquinariaTransporte(id)
        .subscribe(
          data => {
            this.maquinariaTransporte = data;
            ////obtenemos los calculos guardados
            this.calculo1 = this.maquinariaTransporte.maqEquipo_impPeriodo;
            this.calculo2 = this.maquinariaTransporte.maqEquipo_impPorDep;
            ///obteniendo las variables con las que hace los calculos
            this.montoAdep = this.maquinariaTransporte.maqEquipo_importe ;
            this.periodos = this.maquinariaTransporte.maqEquipo_perADeprec;
            ;

            this.modalRef = this.modalService.show(template, this.config);
          },
          error => this.toastr.error(error.message, 'Error!'));


      //// HABILITA O NO el campo de activo/inactivo 
      // porque ya existe uno en el caso de cuando es un nuevo item o un editar
      if (this.deshabilitar) {
        this.nuevoItem = true;
      } else {
        this.deshabilitar = false;
        this.nuevoItem = false;
      }
    }
  }
  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar la maquinaria y equipo de transporte, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._maquinariaTransporteService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Maquinaria y equipo de transporte a sido eliminada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  Calculo1(): number
  {
    //calculo de Monto por período

    var res = this.montoAdep/ this.periodos;
    this.calculo1 = parseFloat(res.toFixed(2));
    if(this.calculo1 >=0)
      {
        return this.calculo1;
      }

      return this.calculo1 = 0;
  }
  Calculo2()
  {
    //calculo de Importe por depreciar
    var res = this.montoAdep - this.periodos;
    this.calculo2 = parseFloat(res.toFixed(2));
     
    return this.calculo2;
  }
  
  getGetMarcas() {
    this._maquinariaTransporteService.getMarcas()
      .subscribe(
        data => {
          this.getMarcas = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  statusToWord(status: boolean) {
    return status ? 'Activo' : 'Inactivo';
  }

  cerrar() {
    this.router.navigate(['/dashboard']);
  }

}